import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

export const TermsOfUse: React.FC = (): React.ReactElement => (
  <Fragment>
    <Helmet>
      <title>scrnli - Screenshot Tool and Editor - Terms of Use</title>
    </Helmet>
    <main className="mb-5">
      <div className="container">
        <div className="page-content-container bg">
          <h1>Terms of Use</h1>
          <p>
            By using our website you accept these terms of use, including our Privacy Policy. If you don’t agree, your
            remedy is to stop using our website. We may update these terms of use from time to time, so you should
            revisit each time you use our website.
          </p>
          <h3>Entire Agreement</h3>
          <p>
            These terms of use forms the entire understanding between you and us with respect to your use of our
            website.
          </p>
          <h3>Disclaimer</h3>
          <p>
            Anything contained on this website is provided “as is” without warranty or condition of any kind. While we
            attempt to ensure that the content of our website is correct, we cannot guarantee its reliability, accuracy
            or completeness because this information changes all the time. You agree that no information or advice
            provided by us constitutes medical, legal or financial advice or advise of any other regulated industry and
            users should not rely exclusively on any such information or advice.
          </p>
          <h3>Limitation of Liability</h3>
          <p>
            We will not be liable to you or any other person or entity for any damages whatsoever arising as a result of
            your use of this website in any way. In addition, where warranties are implied by law, you acknowledge and
            agree that the total aggregate liability to us is limited to the total amount paid by you for the particular
            services that are the subject of the cause of action, even if those services were provided to you without
            cost. This limitation of liability applies to the fullest extent permitted by law, and shall survive any
            termination or expiration of this agreement or your use of this website or the services found on this
            website.
          </p>
          <h3>File ownership and privacy</h3>
          <p>
            When you use our Services, you provide us with your files. Your files are yours. These Terms don’t give us
            any rights to Your Files except for the limited rights that enable us to offer the Services.We need your
            permission to do things like converting Your Files and temporarily storing them (via a private URL that is
            only available to you for downloading the converted file). To convert and deliver converted files, we need
            to access and store your files. You give us permission to do those things. Please also note than we remove
            all files from our servers within 2 hours of conversion.
          </p>
          <h3>Billing</h3>
          <p>
            We will automatically bill the monthly pro package fees to your payment method until your subscription plan
            is cancelled or terminated. If you do not terminate your monthly pro package, it will be automatically
            renewed. The 24-h (24 hour) package does not automatically renew and is only valid for 24 hours. You or
            FreeConvert may cancel or terminate your subscription at any time. Any cancellation will take place from the
            next billing period; i.e. any cancellation will only cancel future billings.
          </p>
          <h3>Cancelling Account</h3>
          <ul>
            <li>You can cancel your subscription from your billing page.</li>
            <li>
              Once logged into the billing page, locate the “Cancel” link for your pro package listed on the
              “Subscriptions” table.
            </li>
            <li>Once you cancel your membership subscription, you will not lose access immediately.</li>
            <li>
              Your membership will continue through the end of your current charge cycle. For example: If your credit
              card is charged on the 10th of the month and you cancel on April 25th, you will not lose access until May
              10th.
            </li>
          </ul>
          <h3>Copyright</h3>
          <p>
            All contents of this website are copyright by FreeConvert.com. Unless otherwise specified, we own or are
            licensed to use the copyright in all material on our website. Please contact us should you require
            permission to reproduce any of the contents of this site. We may or may not be able to authorise use,
            particularly of trademarks or materials of other companies. Only your personal use of our copyright
            materials is permitted, which means that you may access, download, or print such materials for your personal
            use only. Commercial use of any sort is expressly prohibited.
          </p>
        </div>
      </div>
    </main>
  </Fragment>
);
