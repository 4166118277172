import React from 'react';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';

type StandardProps = {
  children: React.ReactElement;
};
export const Standard: React.FC<StandardProps> = ({ children }: StandardProps): React.ReactElement => {
  return (
    <React.Fragment>
      <div
        className="d-grid"
        style={{ minHeight: '100vh', gridTemplateRows: 'auto 1fr auto', gridTemplateColumns: '100%' }}
      >
        <Header />
        {children}
        <Footer />
      </div>
    </React.Fragment>
  );
};
