import React, { useState, useCallback, useEffect, Fragment, ChangeEventHandler } from 'react';
import { Helmet } from 'react-helmet';
import { Formats } from '../constants/Formats';
import { FormatTargets } from '../components/FormatTargets';
import { AdsenseBanner } from '../components/banners/AdsenseBanner';
import { AdsenseFullBanner } from '../components/banners/AdsenseFullBanner';
// import adsImage from './../assets/images/ads.png';
const isProduction = process.env.REACT_APP_TARGET_ENV === 'production';

export const Converters: React.FC = (): React.ReactElement => {
  const [screenWidth, setScreenWidth]: [number, React.Dispatch<React.SetStateAction<number>>] = useState<number>(0);
  const [filter, setFilter]: [string, React.Dispatch<React.SetStateAction<string>>] = useState<string>('');
  const [filteredFormats, setFilteredFormats]: [
    Array<[string, Array<string>]>,
    React.Dispatch<React.SetStateAction<Array<[string, Array<string>]>>>
  ] = useState<Array<[string, Array<string>]>>(Object.entries(Formats));
  const updateFilterValue: ChangeEventHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => setFilter(event.target.value),
    []
  );

  const renderFormatsWithBanner = () => {
    let splitArray = [];
    let formatsInRow = 3;
    if (screenWidth <= 1100) {
      formatsInRow = 2;
    }
    if (screenWidth <= 768) {
      formatsInRow = 1;
    }

    for (let i = 0; i < filteredFormats.length; i += formatsInRow) {
      let ar = [];
      for (let j = 0; j < formatsInRow; j++) {
        ar.push(filteredFormats[i + j]);
      }
      splitArray.push(ar);
    }
    return splitArray.map((subArray: any, index: any) => {
      return (
        <Fragment key={index}>
          <div className="formats-container container">
            {subArray.map((format: [string, Array<string>], subArrayIndex: any) => {
              const [formatFrom, formatTargets] = format;
              return <FormatTargets key={formatFrom} formatFrom={formatFrom} formatTargets={formatTargets} />;
            })}
          </div>
          {isProduction && index < 3 && (
            <div
              className="container"
              style={{
                margin: '50px auto',
              }}
            >
              <AdsenseFullBanner />
            </div>
          )}
        </Fragment>
      );
    });
  };

  const onResize = () => {
    setScreenWidth(document.body.offsetWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    setScreenWidth(document.body.offsetWidth);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect((): void => {
    let formats: Array<[string, Array<string>]> = Object.entries(Formats);

    if (!filter) {
      setFilteredFormats(formats);
      return;
    }

    const mostSuitableFormatMatcher = (format: [string, Array<string>]): boolean => {
      const [formatFrom] = format;
      return !!formatFrom.match(new RegExp(`^${filter}`, 'ig'));
    };
    const mostSuitableFormat = formats.find(mostSuitableFormatMatcher);
    const mostSuitableFormatIndex = formats.findIndex(mostSuitableFormatMatcher);

    if (mostSuitableFormatIndex > -1 && mostSuitableFormat) {
      formats.splice(mostSuitableFormatIndex, 1);
      formats = [mostSuitableFormat, ...formats];
    }

    formats = formats.map((format: [string, Array<string>]): [string, Array<string>] => {
      const [formatFrom, formatTargets] = format;
      return [
        formatFrom,
        formatTargets.filter(
          (formatTarget: string): boolean =>
            !!(formatFrom.match(new RegExp(`${filter}`, 'ig')) || formatTarget.match(new RegExp(`${filter}`, 'ig')))
        ),
      ];
    });

    formats = formats.filter((format: [string, Array<string>]): boolean => {
      const [formatFrom, formatTargets] = format;
      return !!(formatFrom.match(new RegExp(`${filter}`, 'ig')) || formatTargets.length);
    });

    setFilteredFormats(formats);
  }, [filter]);

  return (
    <Fragment>
      <Helmet>
        <title>Free Video Converter</title>
      </Helmet>
      <main className="converters-list-page mb-5">
        <div className="page-main-title container d-flex flex-column justify-content-center align-items-center">
          <h2>Free Video Converter</h2>
          <p>Easily convert your videos to MP4, MOV, AVI, and more.</p>

          {isProduction && (
            <div
              style={{
                marginBottom: '30px',
                // marginTop: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <AdsenseBanner />
            </div>
          )}
          <input
            type="search"
            placeholder="Search format"
            className="search-input"
            value={filter}
            onChange={updateFilterValue}
          />

          {/* <div className="ads">
            <img src={adsImage} alt="" />
          </div> */}
        </div>

        {renderFormatsWithBanner()}
      </main>
    </Fragment>
  );
};
