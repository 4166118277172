import React, { Fragment } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Landing } from './pages/Landing';
import { Converters } from './pages/Converters';
import { Converter } from './pages/Converter';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { TermsOfUse } from './pages/TermsOfUse';
import { ContactUs } from './pages/ContactUs';
import { About } from './pages/About';
import { Applications } from './pages/Applications';
import { HowItWorks } from './pages/HowItWorks';
import { Windows } from './pages/Windows';
import { NotFound } from './pages/NotFound';
import { FileInfo } from './pages/FileInfo';
import { Standard } from './layouts/Standard';
import { History } from './pages/History';

export const App: React.FC = (): React.ReactElement => {
  return (
    <Fragment>
      <Routes>
        <Route
          path="/"
          element={
            <Standard>
              <Landing />
            </Standard>
          }
        />
        <Route
          path="/privacy"
          element={
            <Standard>
              <PrivacyPolicy />
            </Standard>
          }
        />
        <Route
          path="/terms-of-use"
          element={
            <Standard>
              <TermsOfUse />
            </Standard>
          }
        />
        <Route
          path="/contact-us"
          element={
            <Standard>
              <ContactUs />
            </Standard>
          }
        />
        <Route
          path="/about-scrnli"
          element={
            <Standard>
              <About />
            </Standard>
          }
        />
        <Route
          path="/applications"
          element={
            <Standard>
              <Applications />
            </Standard>
          }
        />
        <Route
          path="/files"
          element={
            <Standard>
              <History />
            </Standard>
          }
        />
        <Route
          path="/how-to-use-scrnli-extension"
          element={
            <Standard>
              <HowItWorks />
            </Standard>
          }
        />
        <Route
          path="/scrnli-windows-application"
          element={
            <Standard>
              <Windows />
            </Standard>
          }
        />
        <Route
          path="/online-video-converters"
          element={
            <Standard>
              <Converters />
            </Standard>
          }
        />
        <Route
          path="/:sourceFormat-to-:targetFormat-converter"
          element={
            <Standard>
              <Converter />
            </Standard>
          }
        />
        <Route
          path="/:sourceFormat-to-:targetFormat-converter/:taskId"
          element={
            <Standard>
              <Converter />
            </Standard>
          }
        />
        <Route path="/files/:id" element={<FileInfo />} />
        <Route
          path="/404"
          element={
            <Standard>
              <NotFound />
            </Standard>
          }
        />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </Fragment>
  );
};
