import React, { useEffect } from 'react';

declare const window: any;

export const AdsenseBanner: React.FC = (): React.ReactElement => {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <>
      <ins
        className="adsbygoogle"
        style={{ display: 'inline-block', width: '728px', height: '90px' }}
        data-ad-client="ca-pub-8271281345046833"
        data-ad-format="fluid"
        // data-adtest="on"
        data-ad-slot="4918671255"
      ></ins>
    </>
  );
};
