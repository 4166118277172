export const FormatsMetadata: {
  [key: string]: { name: string; desciption: string };
} = {
  webm: {
    name: 'WEBM',
    desciption: `WebM (WEBM) is a freely-licensed file container designed for the Web. Specifically, it was designed to be compatible with HTML5, originally. It supports chapters, captions, subtitles, metadata tags, streaming, attachments, 3D codecs, 3D containers, and hardware players. WEBM compresses video streams with VP8 or VP9 codecs, and audio with Vorbis or Opus codecs. \n\n VLC media player and MPlayer can open WEBM files on any operating system (OS). Other good choices for opening WEBM include Winamp for Microsoft Windows OS, and Elmedia for Mac OS X.`,
  },
  mp4: {
    name: 'MP4 (MPEG-4)',
    desciption: `MPEG-4 (MP4) is a container video format that can store multimedia data, usually audio and video. It is compatible with a wide range of devices and operating systems, using a codec to compress file size, resulting in a file that is easy to manage and store. It is also a popular video format for streaming over the Internet, such as on YouTube. Many consider MP4 to be one of the best video formats available today. \n\n MP4 files open in the operating system's default video player. Simply double-clicking the file opens it. There is no need for third-party software. On Windows, it opens in Windows Media Player. On Mac, it opens in QuickTime.`,
  },
  mov: {
    name: 'MOV (Apple QuickTime)',
    desciption: `Apple QuickTime (MOV) is a container that can hold various types of multimedia files, including 3D and virtual reality (VR). It is known for being useful for saving multimedia files to a user's device. One of its defining features is that it stores data in movie "atoms" and "tracks" that makes highly-specific editing of the files possible. \n\n By default, a MOV file opens with QuickTime. If the MOV file is Version 2.0 or earlier, then it can open with Windows Media Player, but more-recent versions will not open in this player.`,
  },
  avi: {
    name: 'AVI (Audio Video Interleave)',
    desciption: `Audio Video Interleave (AVI) is a multimedia container developed by Microsoft. AVI is a descendant of the Resource Interchange File Format (RIFF). With the assistance of third-party programs, AVI can support chapters, captions, subtitles, menus, streaming, attachments, and 3D containers. \n\n Microsoft provides a downloadable and free AVI Viewer. Another way to view an AVI file is to use a version of Microsoft Windows Media Player that is compatible with the operating system.`,
  },
  m4v: {
    name: 'M4V (Apple)',
    desciption: `The M4V file format is a video container format developed by Apple and is very similar to the MP4 format. The primary difference is that M4V files may optionally be protected by DRM copy protection. Apple uses M4V to encode video files in its iTunes Store. Unauthorized reproduction of M4V files may be prevented using Apple's FairPlay copy protection. \n\n Besides Apple iTunes and the Apple QuickTime Player, M4V files can also be opened and played with Media Player Classic, K-Multimedia Player, RealPlayer, Zoom Player, VLC media player, MPlayer, DivX Plus Player, and Nero Showtime (included with Nero Multimedia Suite)`,
  },
  hevc: {
    name: 'HEVC (High Efficiency Video Coding)',
    desciption: `High Efficiency Video Coding (HEVC), also known as H.265 and MPEG-H Part 2, is a video compression standard designed as part of the MPEG-H project as a successor to the widely used Advanced Video Coding. In comparison to AVC, HEVC offers from 25% to 50% better data compression at the same level of video quality, or substantially improved video quality at the same bit rate. \n\n The best free and paid video players you can try to help you view HEVC videos - VLC, 5KPlayer,  MPC-HC, DivX Player, Potplayer.`,
  },
  flv: {
    name: 'FLV (Flash Video)',
    desciption: `Flash Live Video (FLV) is, as the name suggests, a type of Flash video. It is a popular format that delivers high-quality, well-synchronized multimedia content, primarily over the Internet. It is also a media container, and as such, uses codecs to compress file size. \n\n By default, FLV opens in Adobe products, namely Animate Creative Cloud (Animate CC) and Flash. It opens best in Adobe Flash version 7 and above. FLV does not support chapters or subtitles, but it does support metadata tags.`,
  },
  '3gp': {
    name: '3GPP (3rd Generation Partnership Project)',
    desciption: `3GPP (3GP) is a multimedia container format designed for third generation (3G) universal mobile telecommunications system (UMTS) networks, which is a global system for mobile (GSM) standard. Since UMTS is a technology for mobile, the 3GP format allows mobile phones on UMTS networks to capture, save, deliver, and play media over high-speed wireless connections. \n\n The best application for opening 3GP is Apple QuickTime. And although 3GP is designed for mobile, the file format opens easily on most operating systems, including Linux, Mac, and Windows.`,
  },
  '3g2': {
    name: '3GPP2 (3rd Generation Partnership Project 2)',
    desciption: `3GPP2 (3G2) is a multimedia container format designed for third generation (3G) code division multiple access (CDMA2000) networks. Since CDMA is a technology for mobile, the 3G2 format allows mobile phones on CDMA networks to capture, save, deliver, and play media over high-speed wireless connections. \n\n The best application for opening 3G2 is Apple QuickTime. And although 3G2 is designed for mobile, the file format opens easily on most operating systems, including Linux, Mac, and Windows.`,
  },
  ogg: {
    name: 'OGG (Ogg Vorbis Compressed)',
    desciption: `Ogg Vorbis (OGG) is a file that uses Ogg Vorbis compression. OGG is a patent-free, royalty-free encoding scheme provided by the Xiph.Org Foundation. Like MP3, OGG files are renowned for their high quality. OGG files include metadata, as well as artist and track title information. \n\n The default program to open an OGG file is VLC media player. Additionally, a great number of other programs can open OGG, such as Windows Media Player, RealPlayer, Winamp, Xine, UltraMixer, and others.`,
  },
  wmv: {
    name: 'WMV (Windows Media Video)',
    desciption:
      "WMV is a video format which is compressed with Windows Media compression and contains a video encoded with one of Microsoft's Windows Media Video. WMV was originally used for Internet streaming applications, but with WMV9 version it has also gained adoption for HD DVD and Blu-ray Disc.",
  },
  ogv: {
    name: 'OGV (Open-source Ogg Container)',
    desciption:
      'OGV is a free lossy video compression format which is developed by Xiph.org. It is principally linked with OGG Vorbis. OGG is a standardized and open bitstream container format which is designed specifically for manipulation and streaming. OGV is related to OGG which is a video bitstream container format. This OGV file format can multiplex numerous separate independent open source CODECs for text, video and audio, like Theora.',
  },
};
