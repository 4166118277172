import React, { useEffect, useState } from 'react';
import './../assets/styles/components/collapsable-section.scss';

type CollapsableSectionProps = {
  title: string;
  collapsed?: boolean;
  children?: React.ReactNode;
};

export const CollapsableSection: React.FC<CollapsableSectionProps> = ({
  title,
  children,
  collapsed = true,
}: CollapsableSectionProps): React.ReactElement => {
  const [isOpen, setIsOpen]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState<boolean>(false);

  useEffect((): void => {
    setIsOpen(!collapsed);
  }, [collapsed]);

  const toggle: Function = (): void => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={'collapsable-section ' + (isOpen ? 'open' : '')} onClick={() => toggle()}>
      <div className="collapse-btn" aria-label="Collapse">
        <div className="line"></div>
        <div className="line line-2"></div>
      </div>
      <h3 className="title">{title}</h3>
      <div className="collapse">{children}</div>
    </div>
  );
};
