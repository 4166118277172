import React, { useEffect } from 'react';

declare const window: any;

export const AdsenseFullBanner: React.FC = (): React.ReactElement => {
  useEffect(() => {
    // try {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
    // } catch (err) {}
  }, []);

  return (
    <>
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-8271281345046833"
        data-ad-format="auto"
        data-full-width-responsive="true"
        // data-adtest="on"
        data-ad-slot="6207702197"
      ></ins>
    </>
  );
};
