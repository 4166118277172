import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { CollapsableSection } from '../components/CollapsableSection';
import extensionIcon from '../assets/images/extension.svg';
import pinIcon from '../assets/images/pin.svg';
import editIcon from '../assets/images/edit.svg';
import { Banner } from '../components/Banner';

export const HowItWorks: React.FC = (): React.ReactElement => (
  <Fragment>
    <Helmet>
      <title>How to take a screenshot or record the screen?</title>
    </Helmet>
    <div className="how-it-works-page">
      <main className="mb-5">
        <div className="container">
          <Banner />

          <div className="page-content-container bg">
            <h1>How it Works</h1>
            <p>
              A simple tutorial about how to create, edit and share the screenshot or record screen video with an option
              to convert or download it.
            </p>
            <CollapsableSection title={'How to install Scrnli Screenshot & Screen Video Recorder?'} collapsed={false}>
              <p>
                Go to the official Chrome Web Store and press the "Add to Chrome" button to add scrnli to your browser.
                After installing the scrnli extension, please click the "Extensions" icon (
                <img src={extensionIcon} alt="" className="icon" />) on the top right, find our extension, and click pin
                extension (
                <img src={pinIcon} alt="" className="icon" />
                ).
              </p>
            </CollapsableSection>

            <CollapsableSection title={'How to take a screenshot with Scrnli?'} collapsed={false}>
              <p>Click the extension logo, in the popup select the needed type of screenshot and click it.</p>
            </CollapsableSection>

            <CollapsableSection title={'How to annotate a screenshot?'} collapsed={false}>
              <p>Take a screenshot and in the opened window add any needed tools from the scrnli toolbar.</p>
            </CollapsableSection>

            <CollapsableSection title={'How to open the settings page?'} collapsed={false}>
              <p>To open the settings page you nee right-click the extension logo and select "Options"</p>
            </CollapsableSection>

            <CollapsableSection title={'How to set up hotkeys for scrnli features?'} collapsed={false}>
              <ul className="no-bullets no-margin">
                <li>1. Open the settings page and click the "customize" link.</li>
                <li>2. In the opened new tab find scrnli.</li>
                <li>
                  3. In the section "Open by" click the edit icon (
                  <img src={editIcon} alt="" className="icon" />
                  ).
                </li>
                <li>4. Enter hotkeys in the text field.</li>
              </ul>
            </CollapsableSection>

            <CollapsableSection title={'How to download a screenshot?'} collapsed={false}>
              <p>
                Take a screenshot and in the opened window on the right top hover over the "Download" button and click
                "Image".
              </p>
            </CollapsableSection>

            <CollapsableSection title={'How to copy to the clipboard a screenshot?'} collapsed={false}>
              <p>Take a screenshot and in the opened window on the right top click the "Copy" button.</p>
            </CollapsableSection>

            <CollapsableSection title={'How to share a screenshot?'} collapsed={false}>
              <p>
                Take a screenshot and in the opened window click the "Share" button on the right-top, in the opened
                popup click the "Copy Link" button, and share the link. (Note. The link will be available within 24
                hours.)
              </p>
            </CollapsableSection>
            <CollapsableSection title={'How to record a video?'} collapsed={false}>
              <p>Click the extension logo, in the popup select "Record Video", in the next slide:</p>

              <ul className="no-bullets no-margin">
                <li>- select video quality</li>
                <li>- select record object (Browser Window, Desktop, Application)</li>
                <li>- set up sound settings</li>
              </ul>
              <p>and click the "Start Recording" button.</p>
            </CollapsableSection>
            <CollapsableSection title={'How annotate a page in a video?'} collapsed={false}>
              <ul className="no-bullets no-margin">
                <li>1. Go to the settings page, in the section "Annotate" move the toggle to the right.</li>
                <li>2. Start recording a video and in the left bottom select tools for annotation.</li>
              </ul>
            </CollapsableSection>
            <CollapsableSection title={'How to stop video record?'} collapsed={false}>
              <p>Click the extension logo, in the popup click the "Stop Recording" button.</p>
            </CollapsableSection>
            <CollapsableSection title={'How to download a video?'} collapsed={false}>
              <p>
                After clicking the "Stop Recording" button in the opened window in the "Download" section click the
                “.webm” button.
              </p>
            </CollapsableSection>
            <CollapsableSection title={'How to convert your .webm video file to other formats?'} collapsed={false}>
              <p>
                After clicking the "Stop Recording" button in the opened window in the "Convert to" section select the
                needed format. (Note. If the file size will be more than 50MB you will be redirected to the website to
                convert this file)
              </p>
            </CollapsableSection>
            <CollapsableSection title={'How to remove the Scrnli?'} collapsed={false}>
              <p>
                Right-click on the extension logo and select "Remove from Chrome". Click the "Remove" button in the
                opened popup.
              </p>
            </CollapsableSection>
          </div>
          <Banner />
        </div>
      </main>
    </div>
  </Fragment>
);
