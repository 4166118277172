import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { CollapsableSection } from '../components/CollapsableSection';
import extensionIcon from '../assets/images/extension.svg';
import bagIcon from '../assets/images/bag.svg';
import homeIcon from '../assets/images/home.svg';
import bugIcon from '../assets/images/bug.svg';
import pipeIcon from '../assets/images/pipe.svg';
import bookIcon from '../assets/images/book.svg';
import calendarIcon from '../assets/images/calendar.svg';
import chatIcon from '../assets/images/chat.svg';
import designIcon from '../assets/images/design.svg';
import windowsIcon from '../assets/images/windows-icon.png';
import windowsBg from '../assets/images/windows-promo.png';
import ill1 from '../assets/images/promo-ill-1.png';
import ill2 from '../assets/images/promo-ill-2.png';
import ill3 from '../assets/images/promo-ill-3.png';
import ill4 from '../assets/images/promo-ill-4.png';
import './../assets/styles/pages/windows.scss';
import chromeWhiteIcon from '../assets/images/windows.svg';
import ltIcon from '../assets/images/lt.svg';
import { AdsenseFullBanner } from '../components/banners/AdsenseFullBanner';
const isProduction = process.env.REACT_APP_TARGET_ENV === 'production';

export const Windows: React.FC = (): React.ReactElement => (
  <Fragment>
    <Helmet>
      <title>Screenshot application for Windows</title>
      <meta
        name="description"
        content="Scrnli is a windows application that provides free and easy Screen Recordings with No Watermarks."
      />
    </Helmet>
    <div className="windows-page">
      <main className="mb-5">
        <section className={'main-section'}>
          <div className={'container'}>
            <div className={'promo-image'}>
              <img src={windowsBg} />
            </div>
            <div>
              <h1>
                Free screen recorder<br></br>
                <b>for Windows</b>
              </h1>
              <p>
                Scrnli is a windows application that provides free and easy Screen Recordings with No Watermarks. With
                Scrnli install you will get a really fast and simple Screenshot maker, video & webcam recorder and easy
                to use cloud library.
              </p>
              <a className={'btn btn-primary'} href={'https://app.scrnli.com/api/version/last'} target={'_blank'}>
                <img src={chromeWhiteIcon} alt="" />
                Download for Windows
                <img src={ltIcon} alt="" className="arrow" />
              </a>
            </div>
          </div>
        </section>
        {isProduction && (
          <div
            className="container"
            style={{
              margin: '30px auto',
            }}
          >
            <AdsenseFullBanner />
          </div>
        )}
        <section className={'promo-users'}>
          <div className={'container'}>
            <h3>
              Scrnli is <b>best</b> for...
            </h3>
            <div className={'promo-list'}>
              <div>
                <img src={bugIcon} />
                <h5>Developers</h5>
                <p>Do code reviews, screencasts and fix bugs faster</p>
              </div>
              <div>
                <img src={chatIcon} />
                <h5>Customer support</h5>
                <p>Scrnli would allow you to resolve customer cases faster</p>
              </div>
              <div>
                <img src={designIcon} />
                <h5>Designers</h5>
                <p>Give or receive illustrative feedback for your designs</p>
              </div>
              <div>
                <img src={bookIcon} />
                <h5>Students</h5>
                <p>Easily create tutorials or highlight unclear parts</p>
              </div>
              <div>
                <img src={bagIcon} />
                <h5>Sales</h5>
                <p>Obtain more customers with personalized demos</p>
              </div>
              <div>
                <img src={homeIcon} />
                <h5>Home use</h5>
                <p>Record and share internal training screencasts privately</p>
              </div>
              <div>
                <img src={calendarIcon} />
                <h5>Project management</h5>
                <p>Assign tasks with high-quality screenshots & videos</p>
              </div>
              <div>
                <img src={pipeIcon} />
                <h5>Business</h5>
                <p>Get your point acrosss faster with screen recordings</p>
              </div>
            </div>
          </div>
        </section>
        {isProduction && (
          <div
            className="container"
            style={{
              margin: '50px auto',
            }}
          >
            <AdsenseFullBanner />
          </div>
        )}
        <section className={'promo-features'}>
          <div className={'container'}>
            <h3>Key features</h3>
            <div>
              <div>
                <img src={ill1} />
              </div>
              <div>
                <h4>Powerful editor</h4>
                <p>
                  There are numerous reasons to edit a screenshot instead of capturing something brand new. The primary,
                  and most obvious reason is to save time. With Scrnli ready-made screenshots can be quickly edited
                  (crop, paint over, add arrows, captions or text).
                </p>
              </div>
            </div>
            <div>
              <div>
                <h4>Simple and comfortable interface</h4>
                <p>
                  Scrnli for Windows has a simple and clean interface, which focuses on a single-screen appearance. Just
                  a single button that keeps all the available functionalities and tools. Simple and intuitive user
                  interface and familiar keyboard shortcuts makes your work with the app easier and faster.
                </p>
              </div>
              <div>
                <img src={ill2} />
              </div>
            </div>
            <div>
              <div>
                <img src={ill3} />
              </div>
              <div>
                <h4>Screen & webcam recording</h4>
                <p>
                  Perfect for recording business meetings, messages to colleagues, explainer videos, video
                  presentations, gaming videos, and much, much more! Record your screen or camera along with your audio.
                  Scrnli for Windows supports all types of screen recordings for free with unlimited usage.
                </p>
              </div>
            </div>
            <div>
              <div>
                <h4>Secure cloud upload</h4>
                <p>
                  Scrnli provides fast and secure cloud service for your screenshots and screen records. To share the
                  video or screenshot, all you have to do is click ‘Copy Link’ or ‘Upload’ button inside the editor
                  window. Then anyone with the link will be able to watch and save your file, like on google drive.
                </p>
              </div>
              <div className={'small-img'}>
                <img src={ill4} />
              </div>
            </div>
          </div>
        </section>
        {isProduction && (
          <div
            className="container"
            style={{
              margin: '0px auto 50px auto',
            }}
          >
            <AdsenseFullBanner />
          </div>
        )}
        <section className={'promo-banner-section'}>
          <div className={'container'}>
            <div>
              <img src={windowsIcon} />
            </div>
            <div>
              <h3>
                One button to <b>make everything</b>
              </h3>
              <p>
                Make, edit and one-click share your screenshots, record your screen and webcam with Scrnli for free!
                We've managed to simplify and speed up the process of making screenshots and screen recordings by 50%
                compared to even the quickest similar services.
              </p>
              <a className={'btn btn-primary'} href={'https://app.scrnli.com/api/version/last'} target={'_blank'}>
                <img src={chromeWhiteIcon} alt="" />
                Download for Windows
                <img src={ltIcon} alt="" className="arrow" />
              </a>
            </div>
          </div>
        </section>
      </main>
    </div>
  </Fragment>
);
