import React, { Fragment } from 'react';
import doneImage from '../../assets/images/done.svg';
import { Task } from '../../types/Task';

type TaskSuccessProps = {
  task: Task;
};
export const TaskSuccess: React.FC<TaskSuccessProps> = ({
  task,
}: TaskSuccessProps): React.ReactElement<TaskSuccessProps> => {
  return (
    <Fragment>
      <div className="task-state task-success">
        <img src={doneImage} alt="" style={{ maxWidth: '150px' }} className="align-self-center" />
        <p className="file-name text-center text-muted">{task.targetFilename}</p>
        <a
          href={task.target}
          className="btn btn-primary d-flex align-items-center"
          target="_blank"
          rel="noreferrer"
          download={task.targetFilename}
        >
          Download File
        </a>
        <p className="another-video text-center">
          <a href={`/${task.sourceFormat}-to-${task.targetFormat}-converter`}>
            <span className="grayscale">Upload another video</span>
          </a>
        </p>
      </div>
    </Fragment>
  );
};
