import axios from 'axios';
import FingerprintJS, { GetResult } from '@fingerprintjs/fingerprintjs';

const buildFingerprintInterceptor = () => {
  const fingerprintProvider = new Promise(async (resolve): Promise<void> => {
    const fingerprintApi = await FingerprintJS.load();
    resolve(await fingerprintApi.get());
  });
  return async (config: any): Promise<any> => {
    const fingerprint: GetResult = await fingerprintProvider as GetResult;
    config.headers.common['x-csrf-token'] = `${fingerprint['visitorId']}`;
    return config;
  };
};

export const Api = axios.create({
  // baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
  responseType: 'json',
});
Api.interceptors.request.use(
  buildFingerprintInterceptor(),
  error => Promise.reject(error)
);
