import React from 'react';
import { NavLink } from 'react-router-dom';

export type FormatTargetsProps = {
  formatFrom: string;
  formatTargets: Array<string>;
};
export const FormatTargets: React.FC<FormatTargetsProps> = ({
  formatFrom,
  formatTargets,
}: FormatTargetsProps): React.ReactElement => {
  return (
    <div className="format-card">
      <h5 className="format-card-title">
        <div className="letter">
          <div>{formatFrom.charAt(0).toUpperCase()}</div>
        </div>
        <span>{formatFrom.toUpperCase()}</span>
      </h5>
      <ul>
        {formatTargets.map((formatTo: string) => (
          <li key={`${formatFrom}-to-${formatTo}`}>
            <NavLink to={`/${formatFrom}-to-${formatTo}-converter`}>
              {formatFrom.toUpperCase()} to {formatTo.toUpperCase()}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};
