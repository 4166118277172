import React, { useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, NavLink } from 'react-router-dom';
import { FormatDetails } from '../components/FormatDetails';
import { FormatTargets } from './../components/FormatTargets';
import { Formats } from '../constants/Formats';
import { TaskBuiler } from './Converter/TaskBuiler';
import { TaskAllocator } from './Converter/TaskAllocator';
import { TaskProcessor } from './Converter/TaskProcessor';
import { TaskSuccess } from './Converter/TaskSuccess';
import { TaskFailure } from './Converter/TaskFailure';
// import adsImage from '../assets/images/ads.png';
import { Task } from '../types/Task';
import { TaskFetcher } from './Converter/TaskFetcher';
import { AdsenseBanner } from '../components/banners/AdsenseBanner';
import { AdsenseFullBanner } from '../components/banners/AdsenseFullBanner';
const isProduction = process.env.REACT_APP_TARGET_ENV === 'production';
type ConverterParams = {
  sourceFormat: string;
  targetFormat: string;
  taskId?: string;
};

export const Converter: React.FC = (): React.ReactElement => {
  const { sourceFormat, targetFormat, taskId } = useParams<ConverterParams>();
  const otherFormats: Function = useCallback((sourceFormat: string, targetFormat: string): Array<string> => {
    if (!(sourceFormat || targetFormat)) {
      return [];
    }
    const targetFormats: Array<string> = Formats[sourceFormat];
    return targetFormats
      .filter((format: string): boolean => ![sourceFormat, targetFormat].includes(format))
      .slice(0, 3);
  }, []);
  const [component, setComponent]: [React.ReactElement, React.Dispatch<React.SetStateAction<React.ReactElement>>] =
    useState<React.ReactElement>(
      taskId ? (
        <TaskFetcher
          taskId={taskId}
          onTaskFetchSuccess={(task: Task): void => {
            renderTaskProcessor(task);
          }}
          onTaskFetchFailure={(error: any): void => {
            renderTaskFailure(error);
          }}
          onCancelation={(): void => {
            renderTaskBuiler();
          }}
        />
      ) : (
        <TaskBuiler
          sourceFormat={sourceFormat as string}
          targetFormat={targetFormat as string}
          onBuild={(task: FormData): void => {
            renderTaskAllocator(task);
          }}
          onError={(error: any): void => {
            renderTaskFailure(error);
          }}
        />
      )
      // <TaskFailure error={'Test.'} tryAgain={(): void => {
      //   renderTaskBuiler();
      // }} />
    );
  const renderTaskBuiler: Function = (): void => {
    setComponent(
      <TaskBuiler
        sourceFormat={sourceFormat as string}
        targetFormat={targetFormat as string}
        onBuild={(formData: FormData): void => {
          renderTaskAllocator(formData);
        }}
        onError={(error: any): void => {
          renderTaskFailure(error);
        }}
      />
    );
  };
  const renderTaskAllocator: Function = (task: FormData): void => {
    setComponent(
      <TaskAllocator
        task={task}
        onAllocate={(task: FormData): void => {
          renderTaskProcessor(task);
        }}
        onCancelation={(): void => {
          renderTaskBuiler();
        }}
        onError={(error: any): void => {
          renderTaskFailure(error);
        }}
      />
    );
  };
  const renderTaskProcessor: Function = (task: Task): void => {
    setComponent(
      <TaskProcessor
        task={task}
        onSuccess={(task: Task): void => {
          renderTaskSuccess(task);
        }}
        onCancelation={(): void => {
          renderTaskBuiler();
        }}
        onFailure={(error: any): void => {
          renderTaskFailure(error);
        }}
      />
    );
  };
  const renderTaskSuccess: Function = (task: Task): void => {
    setComponent(<TaskSuccess task={task} />);
  };
  const renderTaskFailure: Function = (error: any): void => {
    setComponent(
      <TaskFailure
        error={error}
        tryAgain={(): void => {
          // renderTaskBuiler();
          window.location.href = `/${sourceFormat}-to-${targetFormat}-converter`;
        }}
      />
    );
  };

  return (
    <main className="converter-page">
      <Helmet>
        <title>
          {sourceFormat?.toUpperCase()} to {targetFormat?.toUpperCase()} Free Online Converter
        </title>
      </Helmet>
      <div className="container" style={{ zIndex: '10', position: 'relative' }}>
        <div className="d-flex flex-column justify-content-center">
          <div className="page-main-title" style={{ marginBottom: '0px' }}>
            <h1 className="text-center fw-bold">
              {sourceFormat?.toUpperCase()} to {targetFormat?.toUpperCase()} Converter
            </h1>
            <p className="text-center">
              Convert {sourceFormat?.toUpperCase()} to {targetFormat?.toUpperCase()} online, for free.
            </p>
            {isProduction && (
              <div
                style={{
                  marginBottom: '30px',
                  marginTop: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  // position: 'absolute',
                  // top: '-170px',
                }}
              >
                <AdsenseBanner />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="state-component-holder-bg" style={{ zIndex: '10', position: 'relative' }}>
        <div className="container">
          <div className="d-flex flex-column justify-content-center">
            <div className="state-component-holder-container">
              <div className="wrapper">
                <div
                  style={{
                    boxShadow: '60px 60px 180px rgba(27 35 61 / 10%)',
                    backgroundColor: '#ffffff',
                    zIndex: '10',
                    position: 'relative',
                  }}
                  className="state-component-holder"
                >
                  {component}
                </div>
                <div
                  style={{
                    background: '#fff',
                    zIndex: '5',
                    position: 'relative',
                  }}
                  className="state-component-holder-back d-flex"
                ></div>
                <div
                  style={{
                    background: '#fff',
                    zIndex: '1',
                    position: 'relative',
                    opacity: '0.5',
                  }}
                  className="state-component-holder-back state-component-holder-back-2 d-flex"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          zIndex: '1',
        }}
        className="format-steps-container"
      >
        <div className="container">
          <h2 className="text-center fw-bold">How to Convert MOV to MP4?</h2>
          <div className="steps-wrapper">
            <div className="step-wrapper d-flex flex-row flex-lg-row align-items-center">
              <div className="step-count">1</div>
              <p>Click the "Choose Files" button to select your video files.</p>
            </div>
            <div className="step-wrapper d-flex flex-row  flex-lg-row align-items-center">
              <div className="step-count step-orange">2</div>
              <p>Wait when your video file is uploaded and converted.</p>
            </div>
            <div className="step-wrapper d-flex flex-row  flex-lg-row align-items-center">
              <div className="step-count step-green">3</div>
              <p>When the status change to "Done" click the "Download" button</p>
            </div>
          </div>
        </div>
      </div>

      {isProduction && (
        <div
          className="container"
          style={{
            margin: '50px auto',
          }}
        >
          <AdsenseFullBanner />
        </div>
      )}

      <div className="format-details-container container">
        <FormatDetails sourceFormat={sourceFormat as string} selected={true} />
        <FormatDetails sourceFormat={targetFormat as string} selected={false} />
      </div>
      {isProduction && (
        <div
          className="container"
          style={{
            margin: '50px auto',
          }}
        >
          <AdsenseFullBanner />
        </div>
      )}

      <div className="other-formats-container container">
        <div className="d-flex flex-row justify-content-between align-items-center mb-3">
          <h2 className="fw-bold">Other formats</h2>
          <NavLink to="/online-video-converters" className="btn btn-install-light btn-rounded">
            See All Formats
          </NavLink>
        </div>
        <div className="formats-container">
          {otherFormats(sourceFormat as string, targetFormat as string).map((format: string) => (
            <FormatTargets key={format} formatFrom={format as string} formatTargets={Formats[format as string]} />
          ))}
        </div>
      </div>
      {isProduction && (
        <div
          className="container"
          style={{
            margin: '50px auto',
          }}
        >
          <AdsenseFullBanner />
        </div>
      )}
    </main>
  );
};
