export const Formats: { [key: string]: Array<string> } = {
  webm: ['mp4', 'mov', 'avi', 'm4v', 'hevc', 'flv', '3gp', '3g2', 'ogg', 'wmv', 'ogv'],
  mp4: ['webm', 'mov', 'avi', 'm4v', 'hevc', 'flv', '3gp', '3g2', 'ogg', 'wmv', 'ogv'],
  mov: ['webm', 'mp4', 'avi', 'm4v', 'hevc', 'flv', '3gp', '3g2', 'ogg', 'wmv', 'ogv'],
  avi: ['webm', 'mp4', 'mov', 'm4v', 'hevc', 'flv', '3gp', '3g2', 'ogg', 'wmv', 'ogv'],
  m4v: ['webm', 'mp4', 'mov', 'avi', 'hevc', 'flv', '3gp', '3g2', 'ogg', 'wmv', 'ogv'],
  hevc: ['webm', 'mp4', 'mov', 'avi', 'm4v', 'flv', '3gp', '3g2', 'ogg', 'wmv', 'ogv'],
  flv: ['webm', 'mp4', 'mov', 'avi', 'm4v', 'hevc', '3gp', '3g2', 'ogg', 'wmv', 'ogv'],
  '3gp': ['webm', 'mp4', 'mov', 'avi', 'm4v', 'hevc', 'flv', '3g2', 'ogg', 'wmv', 'ogv'],
  '3g2': ['webm', 'mp4', 'mov', 'avi', 'm4v', 'hevc', 'flv', '3gp', 'ogg', 'wmv', 'ogv'],
  ogg: ['webm', 'mp4', 'mov', 'avi', 'm4v', 'hevc', 'flv', '3gp', '3g2', 'wmv', 'ogv'],
  wmv: ['webm', 'mp4', 'mov', 'avi', 'm4v', 'hevc', 'flv', '3gp', '3g2', 'ogg', 'ogv'],
  ogv: ['webm', 'mp4', 'mov', 'avi', 'm4v', 'hevc', 'flv', '3gp', '3g2', 'ogg', 'wmv'],
};
