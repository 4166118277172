import React, { Fragment, useState, useEffect, useCallback, MouseEventHandler } from 'react';
import axios, { CancelTokenSource } from 'axios';
import playbackIcon from '../../assets/images/playback-icon.svg';
import spinnerIcon from '../../assets/images/spinner-icon.svg';
import { TASK_STATUS_CREATED } from '../../constants/TaskStatus';
import { TASK_STATUS_PROCEED } from '../../constants/TaskStatus';
import { TASK_STATUS_SUCCEED } from '../../constants/TaskStatus';
import { TASK_STATUS_FAILED } from '../../constants/TaskStatus';
import { TASK_STATUS_CANCELED } from '../../constants/TaskStatus';
import { Api } from '../../services/Api';
import { Task } from '../../types/Task';

type TaskProcessorProps = {
  task: Task;
  onSuccess: Function;
  onCancelation: Function;
  onFailure: Function;
};

export const TaskProcessor: React.FC<TaskProcessorProps> = ({
  task,
  onSuccess,
  onCancelation,
  onFailure,
}: TaskProcessorProps): React.ReactElement<TaskProcessorProps> => {
  const [progress, setProgress]: [number, React.Dispatch<React.SetStateAction<number>>] = useState<number>(0);
  const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
  useEffect((): (() => void) => {
    let nextCheckTimouted: NodeJS.Timer | undefined;
    const pollTask = async (): Promise<void> => {
      try {
        const request = {
          method: 'GET',
          url: `${process.env.REACT_APP_PUBLIC_URL}/api/v1/tasks/${task.id}`,
          cancelToken: cancelTokenSource.token,
        };
        const { status, data: updatedTask } = await Api.request(request);

        if (status !== 200) {
          onFailure('File conversion is failed.');
          return;
        }

        setProgress(updatedTask.progress);

        switch (updatedTask.status) {
          case TASK_STATUS_CREATED:
          case TASK_STATUS_PROCEED:
            nextCheckTimouted = setTimeout(async () => {
              pollTask().catch(() => {
                onFailure('File conversion is failed.');
              });
            }, 3000);
            break;
          case TASK_STATUS_SUCCEED:
            onSuccess(updatedTask);
            break;
          case TASK_STATUS_FAILED:
            onFailure('File conversion is failed.');
            break;
          case TASK_STATUS_CANCELED:
            onFailure('File conversion is canceled.');
            break;
        }
      } catch (err: any) {
        if (!axios.isCancel(err)) {
          throw err;
        }
      }
    };

    pollTask().catch(() => onFailure('File conversion is failed.'));

    return () => {
      cancelTokenSource.cancel();
      clearTimeout(nextCheckTimouted);
    };
  }, [task, onSuccess, onFailure]);

  const cancelProcessing: MouseEventHandler = useCallback((): void => {
    const cancelTask: Function = async (task: Task): Promise<void> => {
      await Api.request({
        method: 'DELETE',
        url: `${process.env.REACT_APP_PUBLIC_URL}/api/v1/tasks/${task.id}`,
      });
    };
    cancelTokenSource.cancel();
    cancelTask(task);
    onCancelation();
  }, [task, onCancelation]);

  return (
    <Fragment>
      <div className="task-state task-progress">
        <div className="task-state-main">
          <img src={playbackIcon} alt="" style={{ maxWidth: '50px' }} className="task-icon align-self-center" />
          <p className="file-name text-center text-muted">{task?.sourceFilename}</p>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: `${progress}%`,
                background: 'linear-gradient(270deg, #61BC5B 0%, rgba(97, 188, 91, 0.25) 100%)',
              }}
              value-now="0"
              value-min="0"
              value-max="100"
            ></div>
          </div>
          <div className="progress-text d-flex justify-content-center align-items-center">
            <img src={spinnerIcon} alt="" className="rotating" />
            Converting
            <span style={{ color: '#61BC5B' }}>{progress}%</span>
          </div>
        </div>
        <button className="light-btn btn btn-rounded" onClick={cancelProcessing}>
          Cancel
        </button>
      </div>
    </Fragment>
  );
};
