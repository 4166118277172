import React from 'react';
import { Link } from 'react-router-dom';
import logotypeIcon from '../assets/images/logotype.svg';
import './../assets/styles/components/footer.scss';

export const Footer: React.FC = (): React.ReactElement => {
  return (
    <footer id="footer" className="app-footer">
      <div className="container footer-main-content d-flex py-5">
        <figure className="app-logo">
          <Link to="/">
            <img src={logotypeIcon} alt="Logotype" />
          </Link>
        </figure>
        <nav className="d-flex justify-content-end">
          <dl className="col-3">
            <dt className="list-title">Menu</dt>
            <dd>
              <Link to="/">Home</Link>
            </dd>
            <dd>
              <Link to="/online-video-converters">Converter</Link>
            </dd>
            <dd>
              <Link to="/how-to-use-scrnli-extension">How it Works</Link>
            </dd>
            <dd>
              <Link to="/about-scrnli">About</Link>
            </dd>
            <dd>
              <Link to="/contact-us">Contact Us</Link>
            </dd>
            <dd>
              <Link to="/privacy">Privacy Policy</Link>
            </dd>
            {/*<dd>*/}
            {/*  <Link to="/terms-of-use">Terms of Use</Link>*/}
            {/*</dd>*/}
          </dl>
          <dl className="col-3">
            <dt className="list-title">Screenshot</dt>
            <dd>
              <div className="footer-item">Visible Page</div>
            </dd>
            <dd>
              <div className="footer-item">Entire Page</div>
            </dd>
            <dd>
              <div className="footer-item">Selected Area</div>
            </dd>
            <dd>
              <div className="footer-item">Browser Window</div>
            </dd>
          </dl>
          <dl className="col-3">
            <dt className="list-title">Screen Record</dt>
            <dd>
              <div className="footer-item">Browser Window</div>
            </dd>
            <dd>
              <div className="footer-item">Desktop</div>
            </dd>
            <dd>
              <div className="footer-item">Application</div>
            </dd>
          </dl>
          <dl className="col-2">
            <dt className="list-title">Converter</dt>
            <dd>
              <Link to="/webm-to-mp4-converter">WEBM to MP4</Link>
            </dd>
            <dd>
              <Link to="/webm-to-mov-converter">WEBM to MOV</Link>
            </dd>
            <dd>
              <Link to="/webm-to-avi-converter">WEBM to AVI</Link>
            </dd>
            <dd>
              <Link to="/webm-to-m4v-converter">WEBM to M4V</Link>
            </dd>
            <dd>
              <Link to="/webm-to-hevc-converter">WEBM to HEVC</Link>
            </dd>
            <dd>
              <Link to="/webm-to-flv-converter">WEBM to FLV</Link>
            </dd>

            <dd>
              <Link to="/webm-to-3gp-converter">WEBM to 3GP</Link>
            </dd>
            <dd>
              <Link to="/webm-to-3g2-converter">WEBM to 3G2</Link>
            </dd>
            <dd>
              <Link to="/webm-to-ogg-converter">WEBM to OGG</Link>
            </dd>
          </dl>
        </nav>
      </div>
      <div className="container">
        <hr className="w-100" />
      </div>
      <div className="container d-flex flex-column align-items-center text-muted-0 rights">
        &copy; 2023 All rights reserved / Privacy Policy
      </div>
    </footer>
  );
};
