import React, { useState, useEffect } from 'react';
import { Api } from '../services/Api';
import copyLinkIcon from '../assets/images/copy-link.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Helmet } from 'react-helmet';
import { AdsenseBanner } from '../components/banners/AdsenseBanner';
import { AdsenseFullBanner } from '../components/banners/AdsenseFullBanner';
const isProduction = process.env.REACT_APP_TARGET_ENV === 'production';
type FileInfoData = {
  path: string;
  slug: string;
  copied: boolean;
};

export const History = () => {
  const [isLoading, setIsLoading]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState<boolean>(true);
  const [files, setFiles]: [Array<FileInfoData>, React.Dispatch<React.SetStateAction<Array<FileInfoData>>>] = useState<
    Array<FileInfoData>
  >([]);

  const setCopied = (file: FileInfoData, copied: boolean) => {
    let _files = files.map(f => {
      f.copied = false;
      return f;
    });
    let i = _files.findIndex(f => f.path === file.path);
    if (i !== -1) {
      _files[i].copied = copied;
    }
    setFiles(_files);
  };
  useEffect(() => {
    const fetchFiles: Function = async (): Promise<void> => {
      try {
        const request = {
          method: 'GET',
          url: `${process.env.REACT_APP_PUBLIC_URL}/api/v1/files/`,
        };
        const { status, data: files }: { status: number; data: any } = await Api.request(request);
        if (status !== 200) {
          return;
        }
        setFiles(files);
      } catch (err: any) {}
      setIsLoading(false);
    };
    setIsLoading(true);
    fetchFiles();
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>My Screenshots | Scrnli Tool</title>
      </Helmet>
      <main className="mb-5">
        <div className="page-main-title container d-flex flex-column justify-content-center align-items-center">
          <h2>My Screenshots</h2>
          <p>Screenshots will be stored for 24 hours.</p>
          {isProduction && (
            <div
              style={{
                marginBottom: '20px',
                // marginTop: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <AdsenseBanner />
            </div>
          )}
        </div>

        <div className="grid-container container">
          {!isLoading &&
            !!files.length &&
            files.map((file: FileInfoData) => (
              <div className="grid-card" key={file.path}>
                <a href={file.slug} target="_blank" className="grid-card-thumbnail-wrapper">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                    alt=""
                    className="grid-card-thumbnail"
                    style={{
                      background: `url(${process.env.REACT_APP_PUBLIC_URL}/${file.path})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                    }}
                  />
                </a>
                <CopyToClipboard
                  text={`${process.env.REACT_APP_PUBLIC_URL}/files/${file.slug}`}
                  onCopy={() => {
                    setCopied(file, true);
                  }}
                >
                  <div className="grid-card-action">
                    <img src={copyLinkIcon} />
                    <span>{file.copied ? 'Copied' : 'Copy Link'}</span>
                  </div>
                </CopyToClipboard>
              </div>
            ))}
        </div>

        {isProduction && (
          <div
            className="container"
            style={{
              margin: '60px auto',
            }}
          >
            <AdsenseFullBanner />
          </div>
        )}
      </main>
    </React.Fragment>
  );
};
