import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

export const About: React.FC = (): React.ReactElement => (
  <Fragment>
    <Helmet>
      <title>About Scrnli Tool</title>
    </Helmet>
    <main className="mb-5">
      <div className="container">
        <div className="page-content-container bg">
          <h1>About</h1>

          <p>
            Ready to present you a screenshot service with extended functionality that covers lots features related to
            content creation. Here you get a screenshot tool, screen video recorder and option to convert video files to
            other formats.
          </p>
          <p>
            Screen video recorder works to create a video file with further opportunity to download or share promptly.
            Easily cooperate with teammates or family by sharing video content for clearly explaining materials and
            information. Record video from your screen or webcam, save it and share with convenient in using application
            that combine as well screenshot tool and video formats converter.
          </p>
          <p>
            Video file converter, therefore, allows to change video format to runs the same video on other devices. Most
            common video formats are available in Scrnli video converter. Do as written: record screen video, then save
            it to a devise for later watching or share it with friends. As well, option to convert video file to various
            format to open it on other devices.
          </p>
          <p>
            Some words about the screen capture with Scrnli. Creation of a screenshot is easy as it ever could be.
            Choose from the full screen capture or partial area screenshot and take it in a click. Then you can fully
            customize screenshot like add text, draw lines, arrows and shapes. Annotate screenshot with any information
            you want to say for clearly explaining things.
          </p>
          <p>Instantly share the screenshot by a link, save it or print to get a paper materials.</p>
          <p>
            The main advantage of Scrnli Screenshoot Tool is that features for content creation are gathered in one
            place which is reached by single click in a browser. It's an advances service for everyday working with
            screenshots, screen video recording and video files converting that can be shared, printed or saved fast,
            easy and quality.
          </p>
          <p>
            Screenshot tool for making a full page or partial screen capture with further edit, download or text adding.
            The #1 app to make a full webpage screenshot or just any part of it with an opportunity to edit, save and
            download screen capture to your PC. The app is launched from a browser that totally saves your time. Simply
            click the extension icon to choose from options whole page screenshot or a selected part. Lots of features
            at your disposal allow to:
          </p>

          <ul>
            <li>edit and reset changes;</li>
            <li>make one step back/forward;</li>
            <li>add text and graphic elements like pointer, arrow, circle and other.</li>
          </ul>

          <p>
            Useful and friendly design of Scrnli was made with a goal to reach required screens fast, so you can share
            them with colleagues or friends during the work. To avoid long descriptions in chatting just take a
            screenshot and send it to the address.
          </p>
          <p>You can make a screenshot of any website absolutely for free using our app.</p>
        </div>
      </div>
    </main>
  </Fragment>
);
