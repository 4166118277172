import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import pageNotFoundImage from '../assets/images/page-not-found.png';

export const NotFound: React.FC = (): React.ReactElement => {
  return (
    <Fragment>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <div className="container col-12 col-md-6 offset-0 offset-md-3 d-flex flex-column justify-content-center">
        <img src={pageNotFoundImage} alt="" />
        <h1 className="pt-5 pb-4 text-center">404 Oops! Page not found.</h1>
        <p className="text-center pb-5">The requested page doesn't exist.</p>
      </div>
    </Fragment>
  );
};
