import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

export const PrivacyPolicy: React.FC = (): React.ReactElement => (
  <Fragment>
    <Helmet>
      <title>Privacy Policy | Scrnli Tool</title>
    </Helmet>
    <main className="mb-5">
      <div className="container">
        <div className="page-content-container bg">
          <h1>Privacy Policy</h1>
          <p>
            This Privacy Policy governs the manner in which Scrnli.com - Screenshot Tool and Editor collects, uses,
            maintains and discloses information collected from users (each, a "User") of the https://scrnli.com website
            ("Site"). This privacy policy applies to the Site and all products and services offered by Scrnli.com -
            Screenshot Tool and Editor.
          </p>
          <h2>Personal identification information</h2>
          <p>
            We may collect personal identification information from Users in a variety of ways in connection with
            activities, services, features or resources we make available on our Site.. Users may visit our Site
            anonymously. We will collect personal identification information from Users only if they voluntarily submit
            such information to us. Users can always refuse to supply personally identification information, except that
            it may prevent them from engaging in certain Site related activities.
          </p>
          <h2>Non-personal identification information</h2>
          <p>
            We may collect non-personal identification information about Users whenever they interact with our Site.
            Non-personal identification information may include the browser name, the type of computer and technical
            information about Users means of connection to our Site, such as the operating system and the Internet
            service providers utilized and other similar information.
          </p>
          <h2>Web browser cookies</h2>
          <p>
            Our Site may use "cookies" to enhance User experience. User's web browser places cookies on their hard drive
            for record-keeping purposes and sometimes to track information about them. User may choose to set their web
            browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts
            of the Site may not function properly.
          </p>
          <h2>How we use collected information</h2>
          <p>
            Scrnli.com - Screenshot Tool and Editor may collect and use Users personal information for the following
            purposes:
          </p>
          <ul className="no-bullets">
            <li>
              <h3>To improve our Site</h3> <p>We may use feedback you provide to improve our products and services.</p>
            </li>
            <li>
              <h3>To send periodic emails</h3>{' '}
              <p>
                If User decides to opt-in to our mailing list, they will receive emails that may include company news,
                updates, related product or service information, etc. If at any time the User would like to unsubscribe
                from receiving future emails, they may do so by contacting us via our Site.
              </p>
            </li>
          </ul>
          <h2>How we protect your information</h2>
          <p>
            We adopt appropriate data collection, storage and processing practices and security measures to protect
            against unauthorized access, alteration, disclosure or destruction of your personal information, username,
            password, transaction information and data stored on our Site.
          </p>
          <p>For all communication with our servers we are using TLS certificate.</p>
          <h2>Sharing your personal information</h2>
          <p>
            We do not sell, trade, or rent Users personal identification information to others. We may share generic
            aggregated demographic information not linked to any personal identification information regarding visitors
            and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.We
            may use third party service providers to help us operate our business and the Site or administer activities
            on our behalf, such as sending out newsletters or surveys. We may share your information with these third
            parties for those limited purposes provided that you have given us your permission.
          </p>
          <h2>Third party websites</h2>
          <p>
            Users may find advertising or other content on our Site that link to the sites and services of our partners,
            suppliers, advertisers, sponsors, licensors and other third parties. We do not control the content or links
            that appear on these sites and are not responsible for the practices employed by websites linked to or from
            our Site. In addition, these sites or services, including their content and links, may be constantly
            changing. These sites and services may have their own privacy policies and customer service policies.
            Browsing and interaction on any other website, including websites which have a link to our Site, is subject
            to that website's own terms and policies.
          </p>
          <h2>Advertising</h2>
          <p>
            Ads appearing on our site may be delivered to Users by advertising partners, who may set cookies. These
            cookies allow the ad server to recognize your computer each time they send you an online advertisement to
            compile non personal identification information about you or others who use your computer. This information
            allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most
            interest to you. This privacy policy does not cover the use of cookies by any advertisers.
          </p>
          <h2>Google Adsense</h2>
          <p>
            Some of the ads may be served by Google. Google's use of the DART cookie enables it to serve ads to Users
            based on their visit to our Site and other sites on the Internet. DART uses "non personally identifiable
            information" and does NOT track personal information about you, such as your name, email address, physical
            address, etc. You may opt out of the use of the DART cookie by visiting the Google
          </p>
          <h2>Changes to this privacy policy</h2>
          <p>
            Scrnli.com - Screenshot Tool and Editor has the discretion to update this privacy policy at any time. When
            we do, we will revise the updated date at the bottom of this page and send you an email. We encourage Users
            to frequently check this page for any changes to stay informed about how we are helping to protect the
            personal information we collect. You acknowledge and agree that it is your responsibility to review this
            privacy policy periodically and become aware of modifications.
          </p>
          <h2>Your acceptance of these terms</h2>
          <p>
            By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please
            do not use our Site. Your continued use of the Site following the posting of changes to this policy will be
            deemed your acceptance of those changes.
          </p>
        </div>
      </div>
    </main>
  </Fragment>
);
