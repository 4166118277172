import React, { Fragment, useEffect, useState } from 'react';
import failureIcon from '../../assets/images/13.png';
import moment from 'moment';
import 'moment-duration-format';

type TaskFailureProps = {
  error: any;
  tryAgain: Function;
};
export const TaskFailure: React.FC<TaskFailureProps> = ({
  error,
  tryAgain,
}: TaskFailureProps): React.ReactElement<TaskFailureProps> => {
  const message: string = typeof error === 'string' ? error : error.message;
  const [throughputLimitTimeout, setThroughputLimitTimeout]: [number, React.Dispatch<React.SetStateAction<number>>] =
    useState<number>(error.throughputLimitTimeout || 0);
  useEffect((): (() => void) => {
    let clear = () => {};
    if (throughputLimitTimeout > 0) {
      const timer: NodeJS.Timer = setInterval(() => setThroughputLimitTimeout(throughputLimitTimeout - 1), 1000);
      clear = () => clearInterval(timer);
    }
    return clear;
  });
  return (
    <Fragment>
      <div className="task-state task-failure">
        <div className="task-state-main">
          <img src={failureIcon} alt="" className="align-self-center" />
          <p>{message}</p>
        </div>
        <button onClick={() => tryAgain()} className="light-btn btn btn-rounded" disabled={throughputLimitTimeout > 0}>
          {throughputLimitTimeout > 0 &&
            moment.duration(throughputLimitTimeout, 'seconds').format('HH:mm:ss', 0, {
              forceLength: true,
              trim: false,
              precision: 0,
              template: 'HH:mm:ss',
            })}
          {throughputLimitTimeout === 0 && 'Try Again'}
        </button>
      </div>
    </Fragment>
  );
};
