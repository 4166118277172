import React, { useEffect, useCallback, Fragment, MouseEventHandler } from 'react';
import { Api } from '../../services/Api';
import playbackIcon from '../../assets/images/playback-icon.svg';
import spinnerIcon from '../../assets/images/spinner-icon.svg';

type TaskFetcherProps = {
  taskId: string;
  onCancelation: Function;
  onTaskFetchSuccess: Function;
  onTaskFetchFailure: Function;
};

export const TaskFetcher: React.FC<TaskFetcherProps> = ({
  taskId,
  onCancelation,
  onTaskFetchSuccess,
  onTaskFetchFailure,
}: TaskFetcherProps) => {
  useEffect((): void => {
    const prefetch = async (): Promise<void> => {
      try {
        const { status, data: task } = await Api.request({
          method: 'GET',
          url: `${process.env.REACT_APP_PUBLIC_URL}/api/v1/tasks/${taskId}`,
        });
        if (status !== 200) {
          throw new Error('File conversion is failed.');
        }
        if (typeof task !== 'object') {
          throw new Error('File conversion is failed.');
        }
        onTaskFetchSuccess(task);
      } catch (err: any) {
        onTaskFetchFailure(err);
      }
    };
    taskId && prefetch();
  });
  const cancelProcessing: MouseEventHandler = useCallback((): void => {
    const cancelTask: Function = async (): Promise<void> => {
      await Api.request({
        method: 'DELETE',
        url: `${process.env.REACT_APP_PUBLIC_URL}/api/v1/tasks/${taskId}`,
      });
    };
    cancelTask();
    onCancelation();
  }, [taskId, onCancelation]);
  return (
    <Fragment>
      <div className="task-state task-progress">
        <div className="task-state-main">
          <img src={playbackIcon} alt="" style={{ maxWidth: '50px' }} className="task-icon align-self-center" />
          {/* <p className="file-name text-center text-muted">{task?.sourceFilename}</p> */}
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: `${0}%`,
                background: 'linear-gradient(270deg, #61BC5B 0%, rgba(97, 188, 91, 0.25) 100%)',
              }}
              value-now="0"
              value-min="0"
              value-max="100"
            ></div>
          </div>
          <div className="progress-text d-flex justify-content-center align-items-center">
            <img src={spinnerIcon} alt="" className="rotating" />
            Waiting
            <span style={{ color: '#61BC5B' }}>0%</span>
          </div>
        </div>
        <button className="light-btn btn btn-rounded" onClick={cancelProcessing}>
          Cancel
        </button>
      </div>
    </Fragment>
  );
};
