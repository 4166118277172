import React, { Fragment, useState, useEffect, useCallback, MouseEventHandler } from 'react';
import axios, { CancelTokenSource } from 'axios';
import playbackIcon from '../../assets/images/playback-icon.svg';
import uploadingIcon from '../../assets/images/uploading-icon.svg';
import { Api } from '../../services/Api';

class PayloadTooLargeError extends Error {
  throughputLimitTimeout: number = 0;
  constructor(message: string = '', throughputLimitTimeout: number = 0) {
    super(message);
    this.throughputLimitTimeout = throughputLimitTimeout;
  }
}

type TaskAllocatorProps = {
  task: FormData;
  onAllocate: Function;
  onCancelation: Function;
  onError: Function;
};
export const TaskAllocator: React.FC<TaskAllocatorProps> = ({
  task,
  onAllocate,
  onCancelation,
  onError,
}: TaskAllocatorProps): React.ReactElement<TaskAllocatorProps> => {
  const sourceFile: any = task.get('source');
  const source: string = sourceFile.name as string;
  const [progress, setProgress]: [number, React.Dispatch<React.SetStateAction<number>>] = useState<number>(0);
  const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
  useEffect((): (() => void) => {
    const allocateTask = async (): Promise<void> => {
      try {
        const request = {
          method: 'POST',
          url: `${process.env.REACT_APP_API_URL}/api/v1/tasks/`,
          data: task,
          cancelToken: cancelTokenSource.token,
          onUploadProgress: ({ loaded, total }: { loaded: number; total: number }) =>
            setProgress(Math.floor((loaded / total) * 100)),
          validateStatus: (status: number) => status < 600,
        };
        const { status, data } = await Api.request(request);
        switch (status) {
          case 201: {
            onAllocate(data);
            break;
          }
          case 400: {
            throw new Error('The request payload is malformed.');
          }
          case 402: {
            throw new PayloadTooLargeError('Exceeded daily conversion limit (1GB/day).', data.throughputLimitTimeout);
          }
          case 413: {
            throw new Error('The file is too big. Max file size is 500MB.');
          }
          default: {
            throw new Error('File conversion is failed.');
          }
        }
      } catch (err: any) {
        if (!axios.isCancel(err)) {
          throw err;
        }
      }
    };
    allocateTask().catch((error: any): void => {
      onError(error);
    });
    return () => cancelTokenSource.cancel();
  }, [task, onAllocate, onError]);

  const cancelAllocation: MouseEventHandler = useCallback((): void => {
    cancelTokenSource.cancel();
    onCancelation();
  }, [onCancelation]);

  return (
    <Fragment>
      <div className="task-state task-progress">
        <div className="task-state-main">
          <img src={playbackIcon} alt="" style={{ maxWidth: '50px' }} className="task-icon align-self-center" />
          <p className="file-name text-center text-muted">{source}</p>
          <div className="progress">
            <div
              className="progress-bar rounded-2"
              role="progressbar"
              style={{
                width: `${progress}%`,
                background: 'linear-gradient(270deg, #0093FF 0%, rgba(0, 147, 255, 0.25) 100%)',
              }}
              value-now="0"
              value-min="0"
              value-max="100"
            ></div>
          </div>
          <div className="progress-text d-flex justify-content-center align-items-center">
            <img src={uploadingIcon} alt="" />
            Uploading
            <span style={{ color: '#0093FF' }}>{progress}%</span>
          </div>
        </div>
        <button onClick={cancelAllocation} className="light-btn btn btn-rounded">
          Cancel
        </button>
      </div>
    </Fragment>
  );
};
