import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import logotypeIcon from '../assets/images/logotype.svg';
import burgerIcon from '../assets/images/burger.svg';
import burgerCloseIcon from '../assets/images/burger-close.svg';
import windowsIcon from '../assets/images/windows.svg';
import './../assets/styles/components/header.scss';

export const Header: React.FC = (): React.ReactElement => {
  const [isOpen, setIsOpen]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState<boolean>(false);

  return (
    <header className={'app-header ' + (isOpen ? 'open' : '')}>
      <div className="wrapper">
        <div className="container d-flex justify-content-between align-items-center">
          <Link to="/" className="">
            <img src={logotypeIcon} alt="Scrnli.com" />
          </Link>
          <nav className="d-flex">
            <NavLink
              to="/"
              className="d-flex flex-column"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Home
            </NavLink>
            <NavLink
              to="/online-video-converters"
              className="d-flex flex-column"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Converter
            </NavLink>
            <NavLink
              to="/how-to-use-scrnli-extension"
              className="d-flex flex-column"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              How it Works
            </NavLink>
            <NavLink
              to="/files/"
              className="d-flex flex-column"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              My Scrnli
            </NavLink>
            <NavLink
              to="/contact-us"
              className="d-flex flex-column mobile"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Contact Us
            </NavLink>
          </nav>
          <a
            href="/scrnli-windows-application"
            className="btn windows-app-btn btn-rounded d-none d-md-flex align-items-center justify-content-center"
          >
            <img src={windowsIcon} alt="Windows App" width="16" height="16" />
            Windows App
          </a>

          <div
            className={'burger-btn'}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <img src={burgerIcon} alt="" className="burger-icon" />
            <img src={burgerCloseIcon} alt="" className="burger-close-icon" />
          </div>
        </div>
      </div>
    </header>
  );
};
