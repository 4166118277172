import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import bannerWindows from '../assets/images/banner-windows.svg';
import './../assets/styles/components/banner.scss';

export const Banner: React.FC = (): React.ReactElement => {
  return (
    <a className={'windows-banner'} href={'/scrnli-windows-application'}>
      <div className={'banner-content'}>
        <div>
          <p>
            Now available <b>for Windows</b>
          </p>
        </div>
        <div>
          <a className={'button-download'} href={'/scrnli-windows-application'}>
            Download
          </a>
        </div>
      </div>
    </a>
  );
};
