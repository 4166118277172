import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// import { Splide, SplideSlide } from '@splidejs/react-splide';
import { FreeMode, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { Helmet } from 'react-helmet';
// import '@splidejs/react-splide/css';
// import mixImage from '../assets/images/mix.svg';
// import chromeIcon from '../assets/images/chrome-small.svg';
// import chromeGrayscaleIcon from '../assets/images/chrome-grayscale-small.svg';
import chromeWhiteIcon from '../assets/images/chrome-white-small.svg';
// import firefoxIcon from '../assets/images/firefox-small.svg';
import firefoxGrayscaleIcon from '../assets/images/firefox-grayscale-small.svg';
// import firefoxWhiteIcon from '../assets/images/firefox-white-small.svg';
// import edgeIcon from '../assets/images/edge-small.svg';
import edgeGrayscaleIcon from '../assets/images/edge-grayscale-small.svg';
// import edgeWhiteIcon from '../assets/images/edge-white-small.svg';
// import operaIcon from '../assets/images/opera-small.svg';
import operaGrayscaleIcon from '../assets/images/opera-grayscale-small.svg';
// import operaWhiteIcon from '../assets/images/opera-white-small.svg';
import recordImage from '../assets/images/record.svg';
import shotImage from '../assets/images/shot.svg';
import convertImage from '../assets/images/convert.svg';
import ltIcon from '../assets/images/lt.svg';
import shot1Image from '../assets/images/shot-1.svg';
import shot2Image from '../assets/images/shot-2.svg';
import shot3Image from '../assets/images/shot-3.svg';
import rec1Image from '../assets/images/rec-1.svg';
import rec2Image from '../assets/images/rec-2.svg';
import rec3Image from '../assets/images/rec-3.svg';
import starImage from '../assets/images/star.svg';
import appLogoImage from '../assets/images/canvas/scrnli.svg';
import appLogoShadowImage from '../assets/images/canvas/scrnli-background.svg';
// import operaImage from '../assets/images/opera.svg';
// import windowsImage from '../assets/images/windows2.svg';
// import appleImage from '../assets/images/apple2.svg';
// import smileyFinderImage from '../assets/images/smiley-finder.svg';
// import firefoxImage from '../assets/images/firefox2.svg';
import edgeImage from '../assets/images/canvas/edge2.svg';
import chromeImage from '../assets/images/canvas/chrome.svg';
// import androidImage from '../assets/images/android2.svg';
import zoomImage from '../assets/images/canvas/zoom.svg';
import facebookImage from '../assets/images/canvas/facebook.svg';
import twitterImage from '../assets/images/canvas/twitter.svg';
import hangoutsImage from '../assets/images/canvas/hangouts.svg';
import youtubeImage from '../assets/images/canvas/youtube.svg';
import twitchImage from '../assets/images/canvas/twitch.svg';
import { AdsenseBanner } from '../components/banners/AdsenseBanner';
import { AdsenseFullBanner } from '../components/banners/AdsenseFullBanner';
const isProduction = process.env.REACT_APP_TARGET_ENV === 'production';
export const Landing: React.FC = (): React.ReactElement => {
  const pagination = {
    clickable: true,
    renderBullet: (_index: number, className: string): string => {
      return `<span class="${className}"></span>`;
    },
  };
  return (
    <div className="home-page">
      <Helmet>
        <title>Screenshot Tool, Screen Recorder, Video Converter - Scrnli</title>
      </Helmet>
      <main>
        <div className="container d-flex">
          <div className="col-12 col-sm-7 col-md-7 col-lg-6 main-text-wrapper">
            <h1 className="fw-bold fc-primary mb-2 high">
              Take Screenshot
              <img src={starImage} alt="" className="star" />
            </h1>
            <h1 className="fw-bold mb-2">Annotate &amp; Share</h1>
            <p className="text-muted-0 my-4">
              A screenshot tool with a screen video recording feature. Take screen capture with further edit, download,
              or text adding.
            </p>
            <div className="d-flex align-items-center py-4">
              <a
                href="https://chrome.google.com/webstore/detail/scrnli-screenshot-screen/ijejnggjjphlenbhmjhhgcdpehhacaal"
                className="btn btn-primary btn-install btn-rounded"
                title="Install Scrnli"
                target="_blank"
                rel="noreferrer"
              >
                <img src={chromeWhiteIcon} alt="" />
                Install Scrnli
                <img src={ltIcon} alt="" className="arrow" />
              </a>
              <div className="platforms text-muted-1 d-none d-md-flex" style={{ pointerEvents: 'none' }}>
                <span>Coming soon</span>
                <a href="/" className="platform-item opera">
                  <span className="icon">
                    <img src={operaGrayscaleIcon} alt="" />
                  </span>
                </a>
                <a href="/" className="platform-item edge">
                  <span className="icon">
                    <img src={edgeGrayscaleIcon} alt="" />
                  </span>
                </a>
                <a href="/" className="platform-item firefox">
                  <span className="icon">
                    <img src={firefoxGrayscaleIcon} alt="" />
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="d-none d-lg-block col-lg-5" style={{ position: 'relative', overflow: 'hidden' }}>
            {/*<img*/}
            {/*  src={mixImage}*/}
            {/*  alt=""*/}
            {/*  width="500"*/}
            {/*  style={{ width: '600px', position: 'absolute', right: '-25%' }}*/}
            {/*/>*/}
          </div>
        </div>
      </main>
      <aside className="features-section">
        {isProduction && (
          <div
            style={{
              marginBottom: '30px',
              marginTop: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              position: 'absolute',
              top: '-170px',
            }}
          >
            <AdsenseBanner />
          </div>
        )}
        <div className="container" style={{ zIndex: '10', position: 'relative' }}>
          <h3 className="fw-bold section-title">Features</h3>
          <p className="text-muted-0 col-lg-5 section-description">
            Discover Scrnli’s main functions for screenshot creating and screen video recording.
          </p>
          <div className="d-flex flex-column flex-lg-row justify-content-around features-tabs">
            <div className="col-lg-4 d-flex flex-column justify-content-center features-tab-item">
              <div className="image-wrapper d-flex justify-content-center">
                <img src={recordImage} alt="" />
              </div>
              <div className="text-wrapper">
                <h5 className="text-center fw-bold">Screen Recorder</h5>
                <p className="text-center">
                  Capture the main moments from the screen easily to save created video file.
                </p>
              </div>
            </div>
            <div className="col-lg-4 d-flex flex-column justify-content-center features-tab-item">
              <div className="image-wrapper d-flex justify-content-center">
                <img src={shotImage} alt="" />
              </div>
              <div className="text-wrapper">
                <h5 className="text-center fw-bold">Screenshot Tool</h5>
                <p className="text-center">Take a screenshot in a click and annotate it to make it more informative.</p>
              </div>
            </div>
            <div className="col-lg-4 d-flex flex-column justify-content-center features-tab-item">
              <div className="image-wrapper d-flex justify-content-center">
                <img src={convertImage} alt="" />
              </div>
              <div className="text-wrapper">
                <h5 className="text-center fw-bold">Video Converter</h5>
                <p className="text-center">
                  Change file type online to one of the most popular video formats for a few clicks.
                </p>
              </div>
            </div>
          </div>
        </div>
      </aside>
      {isProduction && (
        <div
          className="container"
          style={{
            margin: '50px auto',
          }}
        >
          <AdsenseFullBanner />
        </div>
      )}
      <aside className="how-it-works-section">
        <div className="container">
          <h3 className="fw-bold section-title">How it works</h3>
          <div>
            <h4 className="fw-bold fc-primary">Screenshot</h4>
            {/*<Splide*/}
            {/*  options={{*/}
            {/*    arrows: false,*/}
            {/*    autoWidth: true,*/}
            {/*    drag: false,*/}
            {/*    breakpoints: {*/}
            {/*      600: {*/}
            {/*        perPage: 1,*/}
            {/*        drag: true,*/}
            {/*      },*/}
            {/*      1040: {*/}
            {/*        drag: 'free',*/}
            {/*      },*/}
            {/*    },*/}
            {/*  }}*/}
            {/*  className="d-flex flex-column flex-lg-row justify-content-between slider"*/}
            {/*>*/}
            {/*  <SplideSlide className="col-lg-4"></SplideSlide>*/}
            {/*</Splide>*/}
            <Swiper
              className="d-flex flex-column flex-lg-row justify-content-between slider"
              slidesPerView={1}
              simulateTouch={false}
              freeMode={false}
              pagination={pagination}
              modules={[FreeMode, Pagination]}
              breakpoints={{
                600: {
                  slidesPerView: 'auto',
                  freeMode: true,
                },
              }}
            >
              <SwiperSlide className="col-lg-4">
                <div className="d-flex flex-column justify-content-center slider-item">
                  <img src={shot1Image} alt="" />
                  <h5 className="text-center fw-bold">Take a Screenshot</h5>
                  <p className="text-center">Capture the full page, selected area, or browser window.</p>
                </div>
              </SwiperSlide>
              <SwiperSlide className="col-lg-4">
                <div className="d-flex flex-column justify-content-center slider-item">
                  <img src={shot2Image} alt="" />
                  <h5 className="text-center fw-bold">Annotate a Screenshot</h5>
                  <p className="text-center">Add some text and shapes, or draw whatever signs to pay attention.</p>
                </div>
              </SwiperSlide>
              <SwiperSlide className="col-lg-4">
                <div className="d-flex flex-column justify-content-center slider-item">
                  <img src={shot3Image} alt="" />
                  <h5 className="text-center fw-bold">Share a Screenshot</h5>
                  <p className="text-center">Instantly get a link to a screenshot to share with friends.</p>
                </div>
              </SwiperSlide>
            </Swiper>
            <h4 className="fw-bold fc-primary">Screen Recorder</h4>

            <Swiper
              className="d-flex flex-column flex-lg-row justify-content-between slider"
              slidesPerView={1}
              freeMode={false}
              pagination={pagination}
              modules={[FreeMode, Pagination]}
              breakpoints={{
                600: {
                  slidesPerView: 'auto',
                  freeMode: true,
                },
              }}
            >
              <SwiperSlide className="col-lg-4">
                <div className="d-flex flex-column justify-content-center slider-item">
                  <img src={rec1Image} alt="" />
                  <h5 className="text-center fw-bold">Record Screen</h5>
                  <p className="text-center">Create informative video files with a convenient screen recorder.</p>
                </div>
              </SwiperSlide>
              <SwiperSlide className="col-lg-4">
                <div className="d-flex flex-column justify-content-center slider-item">
                  <img src={rec2Image} alt="" />
                  <h5 className="text-center fw-bold">Download Video</h5>
                  <p className="text-center">Save the video on your device for further usage or sharing.</p>
                </div>
              </SwiperSlide>
              <SwiperSlide className="col-lg-4">
                <div className="d-flex flex-column justify-content-center slider-item">
                  <img src={rec3Image} alt="" />
                  <h5 className="text-center fw-bold">Convert Video</h5>
                  <p className="text-center">Convert video to other formats in seconds to run it on various devices.</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        {isProduction && (
          <div
            className="container"
            style={{
              margin: '30px auto',
            }}
          >
            <AdsenseFullBanner />
          </div>
        )}
      </aside>
      <div className="app-platform-circles w-100" style={{ position: 'relative' }}>
        <div className="canvas">
          <div className="circles-container">
            <div className="circle circle-5"></div>
            <div className="circle circle-4"></div>
            <div className="circle circle-3"></div>
            <div className="circle circle-2"></div>
            <div className="circle circle-1"></div>
            <img src={appLogoShadowImage} alt="" className="app-logo-shadow" />
            <img src={appLogoImage} alt="" className="app-logo" />
            <img src={appLogoImage} alt="" className="app-logo" />
            {/*<img src={circle1Image} alt="" className="circle" />*/}
          </div>
          <div className="platform facebook d-flex align-items-center justify-content-center">
            <img src={facebookImage} alt="" />
          </div>
          <div className="platform hangouts d-flex align-items-center justify-content-center">
            <img src={hangoutsImage} alt="" />
          </div>
          <div className="platform twitter d-flex align-items-center justify-content-center">
            <img src={twitterImage} alt="" />
          </div>
          <div className="platform zoom d-flex align-items-center justify-content-center">
            <img src={zoomImage} alt="" />
          </div>
          <div className="platform twitch d-flex align-items-center justify-content-center">
            <img src={twitchImage} alt="" />
          </div>
          <div className="platform edge d-flex align-items-center justify-content-center">
            <img src={edgeImage} alt="" />
          </div>
          <div className="platform chrome d-flex align-items-center justify-content-center">
            <img src={chromeImage} alt="" />
          </div>
          <div className="platform youtube d-flex align-items-center justify-content-center">
            <img src={youtubeImage} alt="" />
          </div>
          {/*<img*/}
          {/*  src={cBackgroundImage}*/}
          {/*  alt=""*/}
          {/*  style={{ width: '100%' }}*/}
          {/*  className="h-100 w-100"*/}
          {/*/>*/}
        </div>
        <div className="h-100 w-100 d-flex justify-content-center align-items-center flex-column description-section">
          <h4 className="fw-bold text-center">
            Screenshot &amp;
            <br />
            Screen Video Recorder
          </h4>
          <a
            href="https://chrome.google.com/webstore/detail/scrnli-screenshot-screen/ijejnggjjphlenbhmjhhgcdpehhacaal"
            className="btn btn-primary btn-install btn-rounded style-2"
            title="Install Scrnli"
            target="_blank"
            rel="noreferrer"
          >
            <img src={chromeWhiteIcon} alt="" />
            Install Scrnli
            <img src={ltIcon} alt="" className="arrow" />
          </a>
        </div>
      </div>
    </div>
  );
};
