import React, { MouseEventHandler, useState } from 'react';
import { Formats } from '../constants/Formats';
import { FormatsMetadata } from '../constants/FormatsMetadata';
import mediaImage from '../assets/images/media.svg';
import rakeDownImage from '../assets/images/rake-down.svg';
import './../assets/styles/components/format-details-card.scss';

type FormatDetailsProps = {
  sourceFormat: string;
  selected: boolean;
};
export const FormatDetails: React.FC<FormatDetailsProps> = ({
  sourceFormat,
  selected = false,
}: FormatDetailsProps): React.ReactElement => {
  const [descriptionCollapsed, setDescriptionCollapsed]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    useState<boolean>(true);

  const toggleDescriptionCollapsed: MouseEventHandler = (): void => {
    setDescriptionCollapsed(!descriptionCollapsed);
  };

  let l = 110;
  let descriptionCollapsable: boolean = FormatsMetadata[sourceFormat].desciption.length > l;

  const collapsedDesription: Function = (): string => {
    if (descriptionCollapsable) {
      return FormatsMetadata[sourceFormat].desciption.slice(0, l) + '...';
    }
    return FormatsMetadata[sourceFormat].desciption;
  };

  return (
    <div className="format-details-card">
      <div
        className="format-details-card__description"
        style={{
          borderBottom: '1px solid rgba(27, 35, 61, 0.1)',
        }}
      >
        <h5>
          <div className="format-details-card__description__img-wrapper">
            <img src={mediaImage} alt="" />
            <div className="format-details-card__format-badge">{sourceFormat.toUpperCase()}</div>
          </div>
          <span>
            What is an <span className="marked">{FormatsMetadata[sourceFormat].name}</span> file?
          </span>
        </h5>
        {descriptionCollapsed ? (
          <p>{collapsedDesription()}</p>
        ) : (
          <p
            dangerouslySetInnerHTML={{
              __html: FormatsMetadata[sourceFormat].desciption.replaceAll('\n', '<br/>'),
            }}
          ></p>
        )}
        <button
          onClick={toggleDescriptionCollapsed}
          style={{
            border: '1px solid rgba(27, 35, 61, 0.1)',
            width: '100%',
            fontSize: '0.8rem',
            background: selected ? '#fff' : 'transparent',
            display: descriptionCollapsable ? 'block' : 'none',
          }}
          className={'py-2 ' + (descriptionCollapsed ? 'collapsed' : '')}
        >
          <img src={rakeDownImage} className="me-2" alt="" />
          {descriptionCollapsed ? 'Read More' : 'Read Less'}
          <img src={rakeDownImage} className="ms-2" alt="" />
        </button>
      </div>
      <div className="format-list-section">
        <h6 className="format-details-card__formats-title">Convert Your {sourceFormat} files to other formats</h6>
        <ul className="format-details-card__formats-list">
          {Formats[sourceFormat].map((targetFormat: string) => (
            <li key={`${sourceFormat}-to-${targetFormat}`}>
              <a href={`/${sourceFormat}-to-${targetFormat}-converter`}>
                {sourceFormat.toUpperCase()} to {targetFormat.toUpperCase()}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
