export const SupportedMimeTypes: { [key: string]: string } = {
  webm: 'video/webm,audio/webm',
  mp4: 'video/mp4,audio/mp4,application/mp4',
  mov: 'video/quicktime',
  avi: 'video/x-msvideo,video/avi',
  m4v: 'video/x-m4v,video/mp4',
  hevc: 'video/mp4',
  flv: 'video/x-flv',
  '3gp': 'video/3gpp',
  '3g2': 'video/3gpp2,audio/3gpp2',
  ogg: 'video/ogg,application/ogg,audio/ogg',
  wmv: 'video/x-ms-asf,video/x-ms-wmv',
  ogv: 'application/ogg,audio/ogg,video/ogg',
};
