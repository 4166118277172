import React, { useEffect, useState } from 'react';
import { NavLink, useParams, useLocation } from 'react-router-dom';
import { Api } from '../services/Api';
import logotypeImage from '../assets/images/logotype.svg';
import pageNotFoundImage from '../assets/images/page-not-found.png';

type FileInfoParams = {
  id?: string;
};
type FileInfoData = {
  name: string;
  path: string;
  type: string;
};

export const FileInfo: React.FC = (): React.ReactElement => {
  const { id } = useParams<FileInfoParams>();
  const [isLoading, setIsLoading]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState<boolean>(true);
  const [file, setFile]: [FileInfoData, React.Dispatch<React.SetStateAction<FileInfoData>>] = useState<FileInfoData>(
    null!
  );
  const location = useLocation();
  useEffect((): void => {
    const fetchFileInfo: Function = async (): Promise<void> => {
      try {
        const request = {
          method: 'GET',
          url: `${process.env.REACT_APP_PUBLIC_URL}/api/v1/files/${id}`,
        };
        const { status, data: file }: { status: number; data: any } = await Api.request(request);
        if (status !== 200) {
          return;
        }
        setFile(file);
      } catch (err: any) {}
      setIsLoading(false);
    };
    fetchFileInfo();
    const d = document;
    const s = d.createElement('script');
    s.type = 'application/javascript';
    s.src = `https://b.scrnli.com/get-scrnli?&se_referrer=${encodeURIComponent(document.referrer)}&default_keyword=${encodeURIComponent(document.title)}&${window.location.search.replace('?', '&')}&_cid=694554a3-449d-4a7d-5e44-9c1d5a93c700&frm=script`;
    d.getElementById('694554a3-449d-4a7d-5e44-9c1d5a93c700')?.appendChild(s);
  }, [id]);

  return (
    <React.Fragment>
      <div className="container-fixed">
        <div className="header">
          <div className="cont">
            <NavLink to="/" className="logo">
              <img src={logotypeImage} alt="" />
            </NavLink>
            <div className={'header-actions'}>
                <div className="iframe-container">
                  <span id="694554a3-449d-4a7d-5e44-9c1d5a93c700"></span>
                </div>
            {file && (
              <div className="btns">
                <a href={file.path} download={file.name} className="download btn">
                  Download
                </a>
              </div>
            )}
            </div>
          </div>
        </div>
        <div className="content">
          {file && (
            <div className="wrap">
              <div className={`file-preview${file.type === 'entirepage' ? ' entire' : ''}`}>
                {/* ref="filepreview" */}
                {/* :class="{ entire: isEntire }" */}
                <img src={file.path} alt="" />
              </div>
            </div>
          )}
          {!isLoading && !file && (
            <div v-if="!file && loaded" className="notfound">
              <img src={pageNotFoundImage} alt="" />
              <div className="label">Oops! File not found.</div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
