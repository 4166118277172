import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import paintyIcon from '../assets/images/apps/painty.png';

export const Applications: React.FC = (): React.ReactElement => (
  <Fragment>
    <Helmet>
      <title>Scrnli Apps - Tools for study and work time.</title>
    </Helmet>
    <main className="applications-page mb-5">
      <div className="container">
        <div className="d-flex flex-column justify-content-center">
          <div className="page-main-title">
            <h1 className="text-center fw-bold">Our Applications</h1>
            <p className="text-center">Tools for study and work time.</p>
          </div>
        </div>

        <div className="apps-container">
          <a href="https://thepainty.com/" target="_blank" className="application-item">
            <img src={paintyIcon} alt="" />
          </a>
        </div>
      </div>
    </main>
  </Fragment>
);
