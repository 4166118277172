import React, { Fragment, useCallback, useState, useRef, useEffect, ChangeEventHandler, DragEventHandler } from 'react';
import { SupportedMimeTypes } from '../../constants/SupportedMimeTypes';
import uploadingIcon from './../../assets/images/uploading-icon-v2.svg';
const MAX_SOURCE_FILE_SIZE = 500 * 1000 * 1000;

type CreateTaskCallback = (formData: FormData) => void;
type TaskBuilerProps = {
  sourceFormat: string;
  targetFormat: string;
  onBuild: Function;
  onError: Function;
};
export const TaskBuiler: React.FC<TaskBuilerProps> = ({
  sourceFormat,
  targetFormat,
  onBuild,
  onError,
}): React.ReactElement<TaskBuilerProps> => {
  useEffect((): (() => void) => {
    document.documentElement.addEventListener('dragenter', onDocumentDragEnter);
    document.documentElement.addEventListener('dragstart', onDocumentDragEnter);
    return () => {
      document.documentElement.removeEventListener('dragenter', onDocumentDragEnter);
      document.documentElement.removeEventListener('dragstart', onDocumentDragEnter);
    };
  }, []);

  const [isDragging, setIsDragging]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    useState<boolean>(false);
  const [fileDropOverlay, setFileDropOverlay]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    useState<boolean>(false);

  const fileInputElement: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement | null>(null);
  const withdrawValidMimeTypes: Function = (sourceFormat: string): string =>
    sourceFormat && SupportedMimeTypes[sourceFormat];
  const createTask: CreateTaskCallback = useCallback<CreateTaskCallback>(
    (formData: FormData): void => {
      const isValidMimeType = (mimeType: string): boolean => SupportedMimeTypes[sourceFormat].includes(mimeType);

      const source: FormDataEntryValue = formData.get('source') as File;
      if (!source) {
        onError('File is not selected.');
        return;
      }
      if (!isValidMimeType(source.type)) {
        onError('Unsupported file format.');
        return;
      }
      if (source.size > MAX_SOURCE_FILE_SIZE) {
        onError('The file is too big. Max file size is 500MB.');
        return;
      }
      onBuild(formData);
    },
    [sourceFormat, onBuild, onError]
  );
  const handleChosenFile: ChangeEventHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      event.preventDefault();
      const formData: FormData = new FormData();
      const currentTarget: HTMLInputElement = event.currentTarget;
      const [source] = currentTarget.files || [];
      formData.set('source', source);
      formData.append('sourceFormat', sourceFormat as string);
      formData.append('targetFormat', targetFormat as string);
      createTask(formData);
    },
    [createTask, sourceFormat, targetFormat]
  );
  const handleDroppedFile: DragEventHandler = useCallback(
    (event: React.DragEvent<HTMLDivElement>): void => {
      event.preventDefault();
      event.stopPropagation();

      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        // @ts-ignore
        behavior: 'instant',
      });
      setFileDropOverlay(false);

      if (isDragging) {
        setIsDragging(false);
      }

      const dataTransfer: DataTransfer = event.dataTransfer;
      const [source] = dataTransfer.files || [];
      const formData: FormData = new FormData();
      formData.set('source', source);
      formData.append('sourceFormat', sourceFormat as string);
      formData.append('targetFormat', targetFormat as string);
      createTask(formData);
    },
    [createTask, isDragging, setIsDragging, sourceFormat, targetFormat]
  );
  const chooseFileFromFilesystem = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    fileInputElement.current && fileInputElement.current.click();
  };

  const onDocumentDragEnter = (e: any) => {
    if (e.dataTransfer.items.length > 0 && e.dataTransfer.items[0].kind === 'file') {
      setFileDropOverlay(true);
    }
  };

  return (
    <Fragment>
      <div
        className="task-state task-builder converter-state"
        onDrag={(event: React.DragEvent<HTMLDivElement>): void => {
          event.preventDefault();
          event.stopPropagation();
        }}
        onDragStart={(event: React.DragEvent<HTMLDivElement>): void => {
          event.preventDefault();
          event.stopPropagation();
        }}
        onDragOver={(event: React.DragEvent<HTMLDivElement>): void => {
          event.preventDefault();
          event.stopPropagation();
          isDragging || setIsDragging(true);
        }}
        onDragEnter={(event: React.DragEvent<HTMLDivElement>): void => {
          event.preventDefault();
          event.stopPropagation();
          isDragging || setIsDragging(true);
        }}
        onDragLeave={(event: React.DragEvent<HTMLDivElement>): void => {
          event.preventDefault();
          event.stopPropagation();
          isDragging && setIsDragging(false);
        }}
        onDragEnd={(event: React.DragEvent<HTMLDivElement>): void => {
          event.preventDefault();
          event.stopPropagation();
          isDragging && setIsDragging(false);
        }}
        onDrop={handleDroppedFile}
      >
        <div
          className={'file-drop-overlay ' + (fileDropOverlay ? 'open' : '')}
          onDragEnter={(event: React.DragEvent<HTMLDivElement>): void => {
            event.preventDefault();
            event.stopPropagation();
            setFileDropOverlay(true);
          }}
          onDragLeave={(event: React.DragEvent<HTMLDivElement>): void => {
            event.preventDefault();
            event.stopPropagation();
            setFileDropOverlay(false);
          }}
          onDrop={handleDroppedFile}
        >
          <img src={uploadingIcon} alt="" />
          <h2>Drop any file here</h2>
        </div>
        <input
          ref={fileInputElement}
          onChange={handleChosenFile}
          type="file"
          accept={withdrawValidMimeTypes(sourceFormat as string)}
          name="source"
          className="d-none"
        />
        <button className="btn btn-primary" onClick={chooseFileFromFilesystem}>
          Choose Files
        </button>
        <p className="text-center d-none d-lg-flex">Or drop files here. Max file size is 500MB.</p>
      </div>
    </Fragment>
  );
};
