import React, { Fragment, useCallback, useState, FormEventHandler } from 'react';
import contactIcon from '../assets/images/contact-icon.svg';
import infoIcon from '../assets/images/info-circle-fill.svg';
import exclamationIcon from '../assets/images/exclamation-triangle-fill.svg';
import { Helmet } from 'react-helmet';
import { Api } from '../services/Api';

export const ContactUs: React.FC = (): React.ReactElement => {
  const [isSending, setIsSending]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState<boolean>(false);
  const [isSuccess, setIsSuccess]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState<boolean>(false);
  const [isFailure, setIsFailure]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState<boolean>(false);
  const onSubmit: FormEventHandler = useCallback(async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setIsSuccess(false);
    setIsFailure(false);
    setIsSending(true);
    const form: HTMLFormElement = event.currentTarget;
    const formData: FormData = new FormData(form);
    try {
      const response: Response = await Api.request({
        method: 'POST',
        url: `${process.env.REACT_APP_PUBLIC_URL}/api/v1/feedbacks/`,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        data: JSON.stringify(Object.fromEntries(formData)),
      });

      const { status } = response;
      if (status === 204) {
        setIsSuccess(true);
        form.reset();
      } else {
        setIsFailure(true);
      }
    } catch (err: unknown) {
      setIsFailure(true);
    } finally {
      setIsSending(false);
    }
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Contact Us | Scrnli Tool</title>
      </Helmet>
      <div className="contact-us-page">
        <main className="mb-5">
          <div className="container">
            <div className="page-content-container bg contact-form">
              <h1>
                <img src={contactIcon} alt="" />
                Contact us
              </h1>
              <p>If you have any questions or suggestions, please, use the form below</p>
              <form method="post" onSubmit={onSubmit}>
                <div className="row py-4">
                  <div className="col-12 col-md py-1 py-md-0">
                    <input
                      name="name"
                      type="text"
                      placeholder="Your name"
                      className="form-control rounded-4 p-3"
                      required={true}
                      disabled={isSending}
                    />
                  </div>
                  <div className="col-12 col-md py-1 py-md-0">
                    <input
                      name="email"
                      type="email"
                      placeholder="Your email"
                      className="form-control rounded-4 p-3"
                      required={true}
                      disabled={isSending}
                    />
                  </div>
                </div>
                <textarea
                  name="message"
                  rows={8}
                  placeholder="Please specify the exact reason and the website where Scrn.li it doesn't work."
                  className="form-control rounded-4 p-3"
                  required={true}
                  disabled={isSending}
                ></textarea>
                <div className="d-flex align-items-center submit-wrap">
                  <button
                    className="btn btn-primary me-4 px-5 py-3 rounded-4 d-flex align-items-center justify-content-center"
                    disabled={isSending}
                  >
                    {isSending && <span className="spinner spinner-border spinner-border-sm"></span>}
                    {isSending || (
                      <>
                        <span className="d-flex text-nowrap">Send Message</span>
                      </>
                    )}
                  </button>
                  <p className="m-0 text-muted">
                    {isSuccess && (
                      <span>
                        <img src={infoIcon} alt="" className="me-2" />
                        We got your message. Thank you!
                      </span>
                    )}
                    {isFailure && (
                      <span>
                        <img src={exclamationIcon} alt="" className="me-2" />
                        Your message message has not sent because of an error.
                      </span>
                    )}
                    {isSuccess || isFailure || (
                      <span>Your feedback helps us improve the product for everyone, thank you. 😉</span>
                    )}
                  </p>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    </Fragment>
  );
};
